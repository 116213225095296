import React from "react"
import gatsbysvg from "../../assets/images/svg/gatsby.svg"
//import mariadbsvg from "../../assets/images/svg/maria-db.svg"
import npmsvg from "../../assets/images/svg/npm-logo-black.svg"
import cloudflaresvg from "../../assets/images/svg/cloudflare.svg"
import awssvg from "../../assets/images/svg/aws.svg"
import githubsvg from "../../assets/images/svg/github.svg"
import htmlsvg from "../../assets/images/svg/html5.svg"
import jssvg from "../../assets/images/svg/javascript-shield.svg"
import reactsvg from "../../assets/images/svg/react.svg"
import useSiteMetadata from "../hooks/sitemetadata"
import useCurrentBuild from "../hooks/currentbuild"
import FlHdDiv from "../components/flhddiv"
import useVersion from "../hooks/version"
import Row from "../components/row"
import Container from "../components/container"
import BlueLink from "../components/buttons/bluelink"
import BaseLink from "../components/buttons/baselink"
import MainCard from "../components/maincard"
import MainSideCol from "../components/mainsidecol"
import PageLayout from "../layouts/pagelayout"

const LOGO_CLASS = "h-10"

const Help = () => {
  const { copyright } = useSiteMetadata()
  const currentDate = useCurrentBuild()
  const version = useVersion()
  const day = currentDate.format("Do")

  return (
    <PageLayout
      title="Help"
      path="/help"
      crumbs={[["Help", "/help"]]}
      bgColorClass="bg-white"
      showLogo={false}
      index={false}
    >
      <FlHdDiv>
        <Container>
          <MainSideCol>
            <>
              <MainCard>
                <Row>
                  {/* <FlatCard> */}
                  <div className="font-medium">{copyright}</div>
                </Row>

                <Row className="mt-1">
                  <div>Version: {version}</div>
                </Row>

                <Row className="mt-1">
                  <div>
                    Build Date: {currentDate.format("ddd MMM DD, YYYY")}
                  </div>
                </Row>

                {/* <Row isCentered={true} className="mt-1">
            <div>App ID: {uid}</div>
          </Row> */}

                <Row>
                  <div className="mt-8">
                    Developed by{" "}
                    <BlueLink to="/people/antony-holmes">
                      Antony Holmes
                    </BlueLink>
                  </div>
                </Row>
              </MainCard>

              <Row>
                <div className="mt-8">
                  This website was built using the following technologies:
                </div>
              </Row>

              <ul className="flex flex-row flex-wrap justify-between items-stretch mt-8 -mx-2">
                <li className="block mb-8 mx-2">
                  <BaseLink to="https://www.w3.org/html/">
                    <img
                      src={htmlsvg}
                      className={LOGO_CLASS}
                      alt="HTML5 logo"
                    />
                  </BaseLink>
                </li>
                <li className="block mb-8 mx-2">
                  <BaseLink to="https://developer.mozilla.org/en-US/docs/Web/JavaScript/">
                    <img src={jssvg} className={LOGO_CLASS} alt="JS logo" />
                  </BaseLink>
                </li>
                <li className="block mb-8 mx-2">
                  <BaseLink to="https://reactjs.org/">
                    <img
                      src={reactsvg}
                      className={LOGO_CLASS}
                      alt="React logo"
                    />
                  </BaseLink>
                </li>
                <li className="block mb-8 mx-2">
                  <BaseLink to="https://www.gatsbyjs.org">
                    <img
                      src={gatsbysvg}
                      className={LOGO_CLASS}
                      alt="Gatsby logo"
                    />
                  </BaseLink>
                </li>
                <li className="block mb-8 mx-2">
                  <BaseLink to="https://www.npmjs.com/">
                    <img src={npmsvg} className={LOGO_CLASS} alt="NPM logo" />
                  </BaseLink>
                </li>

                <li className="block mb-8 mx-2">
                  <BaseLink to="https://www.cloudflare.com/">
                    <img
                      src={cloudflaresvg}
                      className={LOGO_CLASS}
                      alt="Cloudflare logo"
                    />
                  </BaseLink>
                </li>
                <li className="block mb-8 mx-2">
                  <BaseLink to="https://aws.amazon.com/">
                    <img src={awssvg} className={LOGO_CLASS} alt="AWS logo" />
                  </BaseLink>
                </li>
                <li className="block mb-8 mx-2">
                  <BaseLink to="https://github.com/">
                    <img
                      src={githubsvg}
                      className={LOGO_CLASS}
                      alt="GitHub logo"
                    />
                  </BaseLink>
                </li>
              </ul>
            </>
            <></>
          </MainSideCol>
        </Container>
      </FlHdDiv>
    </PageLayout>
  )
}

export default Help
